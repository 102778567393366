import { getUTCDateByDate, getUTCDateByString } from '../helpers/TimeHelper';
import { AccountsFormViewModel } from '../models/Consumer/Account/AccountsFormViewModel';
import { CustomerNumbersFormViewModel } from '../models/Consumer/Account/CustomerNumbersFormViewModel';
import { AccountModel } from '../models/Consumer/Account/AccountModel';
import { AccountsModel } from '../models/Consumer/Account/AccountsModel';
import { SmIdModel } from '../models/Consumer/SmIdModel';
import { SmIdsModel } from '../models/Consumer/SmIdsModel';
import { TripsModel } from '../models/Consumer/ApiAccess/TripsModel';
import { ConsumerRequestModel } from '../models/Consumer/ConsumerRequestModel';
import { ConsumerResponseModel } from '../models/Consumer/ConsumerResponseModel';
import { AccountsFormSaveModel } from '../models/Consumer/Account/AccountsFormSaveModel';

export const mapToAccountsModel = (model: ConsumerResponseModel): AccountsModel => ({
  ids:
    model.trips?.smIds?.ids?.map((i) => ({
      ...i,
      isInclusive: i.customerNumbers?.isInclusive ?? false,
    })) ?? [],
  isInclusive: model.trips?.smIds?.isInclusive ?? true,
});

export const mapToAccountsModels = (model: AccountsFormSaveModel, prevModel?: AccountModel): AccountModel[] =>
  model.accountsIds.map((id) => getAccountModel(id, model.viewModel, prevModel));

const getAccountModel = (
  id: string,
  viewModel: AccountsFormViewModel,
  prevModel?: AccountModel,
): AccountModel => ({
  ...prevModel,
  id: id ?? '',
  enabled: viewModel.enabled,
  startDate: viewModel.startDate && getUTCDateByDate(viewModel.startDate).toJSON(),
  endDate: viewModel.endDate && getUTCDateByDate(viewModel.endDate).toJSON(),
  customerNumbers: {
    ids:
      viewModel.isInclusive === prevModel?.customerNumbers?.isInclusive
        ? [...(prevModel?.customerNumbers?.ids ?? [])]
        : [],
    isInclusive: viewModel.isInclusive || false,
  } as SmIdsModel,
});

export const updateConsumerAccounts = (
  consumer: ConsumerResponseModel,
  accountsModels: AccountModel[],
  accountsIds: string[],
): ConsumerRequestModel => {
  return {
    ...consumer,
    trips: {
      ...consumer.trips,
      smIds: consumer.trips?.smIds
        ? {
            ...consumer.trips.smIds,
            ids: consumer.trips.smIds.ids
              ? [
                  ...(consumer.trips.smIds.ids?.filter((smid) => !accountsIds.includes(smid.id)) ?? []),
                  ...accountsModels,
                ]
              : [...accountsModels],
          }
        : {
            ids: [...accountsModels],
            isInclusive: true,
          },
    } as TripsModel,
  };
};

export const mapToCustomerNumberModel: (model: CustomerNumbersFormViewModel) => SmIdModel = (
  model: CustomerNumbersFormViewModel,
) => {
  return {
    id: model.id ?? '',
    enabled: model.enabled,
    startDate: model.startDate && getUTCDateByDate(model.startDate).toJSON(),
    endDate: model.endDate && getUTCDateByDate(model.endDate).toJSON(),
  };
};

export const updateConsumerAddCustomerNumbers = (
  model: ConsumerResponseModel,
  customerNumber: SmIdModel,
  accountId: string,
  prevId?: string,
): ConsumerRequestModel => {
  if (!model.trips || !model.trips.smIds) {
    throw new Error('No accounts model provided');
  }
  return {
    ...model,
    trips: {
      ...model.trips,
      smIds: {
        ...model.trips?.smIds,
        ids: [
          ...model.trips.smIds.ids.map((account) => {
            if (accountId !== account.id) {
              return account;
            }
            return {
              ...account,
              customerNumbers: account.customerNumbers
                ? {
                    ids: [
                      ...(account.customerNumbers.ids?.filter((cn) => cn.id !== prevId) ?? []),
                      customerNumber,
                    ],
                    isInclusive: account.customerNumbers.isInclusive,
                  }
                : {
                    ids: [customerNumber],
                    isInclusive: false,
                  },
            };
          }),
        ],
      },
    } as TripsModel,
  };
};

export const updateConsumerModelIsInclusive = (
  model: ConsumerResponseModel,
  isInclusive: boolean,
): ConsumerRequestModel => {
  return {
    ...model,
    trips: {
      ...model.trips,
      smIds: {
        isInclusive: isInclusive,
      },
    } as TripsModel,
  };
};

export const updateConsumerModelAccountsStatus = (
  consumer: ConsumerResponseModel,
  accountIds: string[],
  enabled: boolean,
): ConsumerRequestModel => {
  if (!consumer.trips || !consumer.trips.smIds) {
    throw new Error('No accounts model provided');
  }
  return {
    ...consumer,
    trips: {
      ...consumer.trips,
      smIds: {
        ...consumer.trips?.smIds,
        ids: [
          ...consumer.trips.smIds.ids.map((account) => {
            if (accountIds.includes(account.id)) {
              return {
                ...account,
                enabled: enabled,
              };
            }
            return account;
          }),
        ],
      },
    } as TripsModel,
  };
};

export const mapToCustomerNumberViewModel = (source?: SmIdModel): CustomerNumbersFormViewModel => {
  return {
    id: source?.id,
    startDate: source?.startDate ? getUTCDateByString(source.startDate) : undefined,
    endDate: source?.endDate ? getUTCDateByString(source.endDate) : undefined,
    enabled: source?.enabled,
  };
};

export const mapToAccountsFormViewModel = (source?: AccountModel): AccountsFormViewModel => {
  return source
    ? {
        id: source.id,
        startDate: source.startDate ? getUTCDateByString(source.startDate) : undefined,
        endDate: source.endDate ? getUTCDateByString(source.endDate) : undefined,
        enabled: source.enabled,
        isInclusive: source.isInclusive,
      }
    : {
        isInclusive: false,
        enabled: false,
      };
};
