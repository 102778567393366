import { createSlice } from '@reduxjs/toolkit';
import { ApiError } from '../../models/Api/ApiError';
import { PageModel } from '../../models/Store/PageModel{T}';
import { consumerInfoPrefix } from '../actions/actionTypes';
import { fetchConsumerInfoAction } from '../actions/consumerInfoActions';
import { ConsumerResponseModel } from './../../models/Consumer/ConsumerResponseModel';

interface ConsumerInfos {
  [key: string]: ConsumerResponseModel;
}

const initialState: PageModel<ConsumerInfos> = {
  items: {} as ConsumerInfos,
  isLoading: false,
};

const consumerInfoSlice = createSlice({
  name: consumerInfoPrefix,
  initialState,
  reducers: {
    reset: () => initialState,
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsumerInfoAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchConsumerInfoAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.items = { ...state.items, [payload.consumerId ?? '']: payload };
        state.error = undefined;
      })
      .addCase(fetchConsumerInfoAction.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload as ApiError;
      });
  },
});

export default consumerInfoSlice.reducer;
export const resetConsumer = consumerInfoSlice.actions.reset;
