import { Action, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumerRequestModel } from '../../models/Consumer/ConsumerRequestModel';
import { ConsumerService } from '../../services/ConsumerService';
import {
  clearConsumersErrorActionName,
  clearConsumersSuccessMessageActionName,
  createConsumerActionName,
  fetchConsumersActionName,
  updateConsumerActionName,
} from './actionTypes';

const service = ConsumerService;

export const fetchConsumersAction = createAsyncThunk(fetchConsumersActionName, async (_, thunkApi) => {
  try {
    return await service.getConsumers();
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

const upsertConsumer: AsyncThunkPayloadCreator<string, ConsumerRequestModel> = async (model, thunkApi) => {
  try {
    return await service.upsertConsumerModel(model);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
};

export const createConsumerAction = createAsyncThunk<string, ConsumerRequestModel>(
  createConsumerActionName,
  upsertConsumer,
);

export const updateConsumerAction = createAsyncThunk<string, ConsumerRequestModel>(
  updateConsumerActionName,
  upsertConsumer,
);

export const clearConsumersErrorAction: () => Action = () => {
  return {
    type: clearConsumersErrorActionName,
  };
};

export const clearConsumersSuccessMessageAction: () => Action = () => {
  return {
    type: clearConsumersSuccessMessageActionName,
  };
};
