import React from 'react';
import { ApiAccess } from '../../../enums/ApiAccess';
import { Checkbox, Icon, Popup } from 'semantic-ui-react';
import { i18n } from '../../../services/i18n';
import './styles.scss';

interface Props {
  apiAccessValue?: ApiAccess[];
  oauthAccessValue: boolean;
  isCreating: boolean;
  isEditing: boolean;
  isUserAdmin: boolean;
  onApiAccessChange?: (value: ApiAccess[]) => void;
  onOauthAccessChange?: (value: boolean) => void;
}

const ConsumerApiAccess: React.FC<Props> = ({
  onApiAccessChange = () => undefined,
  onOauthAccessChange = () => undefined,
  oauthAccessValue,
  apiAccessValue = [],
  isCreating,
  isEditing,
  isUserAdmin,
}) => {
  const accessTypes = [
    ApiAccess.Expenses,
    ApiAccess.Trips,
    ApiAccess.Tickets,
    ApiAccess.FlightAlerts,
    ApiAccess.Receipts,
    ApiAccess.Enh,
    ApiAccess.WaiversMatching,
    ApiAccess.WaiversStandAlone,
    ApiAccess.Xx1,
  ];

  const handleApiAccessChange = (checked: boolean, access: ApiAccess) => {
    const newApiAccessValue = getNewApiAccessValue(checked, access);
    onApiAccessChange(newApiAccessValue);
    if (newApiAccessValue.length > 0) {
      onOauthAccessChange(true);
    } else {
      onOauthAccessChange(false);
    }
  };

  const handleOauthAccessChange = (checked: boolean) => {
    onOauthAccessChange(!checked);
  };

  const getNewApiAccessValue = (checked: boolean, access: ApiAccess) => {
    if (checked) {
      return access === ApiAccess.WaiversStandAlone
        ? [
            ...apiAccessValue.filter(
              (a) => a !== ApiAccess.WaiversStandAlone && a !== ApiAccess.WaiversMatching,
            ),
          ]
        : [...apiAccessValue.filter((a) => a !== access)];
    } else {
      return access === ApiAccess.WaiversMatching
        ? [...apiAccessValue, ApiAccess.WaiversStandAlone, ApiAccess.WaiversMatching]
        : [...apiAccessValue, access];
    }
  };

  return (
    <div>
      <h4 className="create-consumer-api-title">
        {i18n('ConsumerForm_ApiAccessTitle')}
        {isCreating && !isUserAdmin && (
          <Popup
            offset={[-11]}
            content={i18n('ConsumerForm_Create_AllowedApiAccess_Hint')}
            trigger={<Icon name="info circle" color="grey" />}
          />
        )}
        {isEditing && !isUserAdmin && (
          <Popup
            offset={[-11]}
            content={i18n('ConsumerForm_Edit_AllowedApiAccess_Hint')}
            trigger={<Icon name="info circle" color="grey" />}
          />
        )}
      </h4>
      <div className="create-consumer-api-content">
        {accessTypes.map((t) => {
          return (
            <div key={t}>
              <Checkbox
                checked={apiAccessValue.some((v) => v === t)}
                className="checkbox-input"
                label={i18n(`ApiAccess_${ApiAccess[t]}`)}
                disabled={(!isEditing && !isCreating) || !isUserAdmin}
                onChange={() => {
                  handleApiAccessChange(
                    apiAccessValue.some((v) => v === t),
                    t,
                  );
                }}
              />
            </div>
          );
        })}
      </div>
      {(!isCreating || isUserAdmin) && (
        <div>
          <h4 className="create-consumer-api-title">{i18n('ConsumerForm_OauthAccessTitle')}</h4>
          <Checkbox
            checked={oauthAccessValue}
            className="checkbox-input"
            disabled={(!isEditing && !isCreating) || !isUserAdmin}
            onChange={() => handleOauthAccessChange(oauthAccessValue)}
          />
        </div>
      )}
    </div>
  );
};

export default ConsumerApiAccess;
