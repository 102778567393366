import { Action, createAsyncThunk } from '@reduxjs/toolkit';
import { ConsumerResponseModel } from '../../models/Consumer/ConsumerResponseModel';
import { ConsumerService } from '../../services/ConsumerService';
import { clearConsumerInfoErrorActionName, fetchConsumerInfoActionName } from './actionTypes';

const service = ConsumerService;

export const fetchConsumerInfoAction = createAsyncThunk<ConsumerResponseModel, string>(
  fetchConsumerInfoActionName,
  async (id, thunkApi) => {
    try {
      return await service.getConsumer(id);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const clearConsumerInfoErrorAction: () => Action = () => {
  return {
    type: clearConsumerInfoErrorActionName,
  };
};
