import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

import { Permission } from '../../../../enums/Permission';
import { cleanupTrueValues } from '../../../../helpers/ObjectHelper';
import { useBreakpoint } from '../../../../hooks/BreakPointProvider/breakpoint';
import { ScopesPresetModel } from '../../../../models/Consumer/Scopes/ScopesPresetModel';
import { TripAccessSettingEntry } from '../../../../models/TripAccess/TripAccessSettingEntry';
import { i18n } from '../../../../services/i18n';
import CanUser from '../../../Permissions/CanUser';
import RadioGroup from '../../../RadioGroup/RadioGroup';
import TripAccessBaseContainer from '../TripAccessBaseContainer/TripAccessBaseContainer';
import TripAccessAdvancedSettings from '../TripAccessForm/TripAccessAdvancedSettings/TripAccessAdvancedSettings';
import { useAdvancedSettings } from '../TripAccessForm/TripAccessAdvancedSettings/useAdvancedSettings';
import { getTripAccessPresetOption } from '../TripAccessTabOptionsMapper';
import { TripsModel } from '../../../../models/Consumer/ApiAccess/TripsModel';
import GeneralAccessInfo from './GeneralAccessInfo/GeneralAccessInfo';
import TripAccessPresetVisualization from './TripAccessPresetVisualization';
import { getSelectedPreset } from '../../../../mappers/DataSourceMapper';
import { useUserPermissions } from '../../../../hooks/Auth/useUserPermissions';
import { userRoles } from '../../../../helpers/PermissionsHelper';
import { getWithPopupTripAccessOptions } from '../../../ConsumerForm/helpers';
import { getTripAccessOptionsRules } from '../helpers';

import './styles.scss';

interface Props {
  trips?: TripsModel;
  scopesPresets: ScopesPresetModel[];
  onEdit: () => void;
  getAdvancedSettingsEntries: () => TripAccessSettingEntry[];
}

const TripAccessInfo: React.FC<Props> = ({ trips, onEdit, scopesPresets, getAdvancedSettingsEntries }) => {
  const breakpoints = useBreakpoint();
  const [settingsEntries, setSettingsEntries] = useAdvancedSettings(getAdvancedSettingsEntries, trips);
  const hasAccessToPii = trips?.hasAccessToPii ?? false;
  const selectedPresetId = getSelectedPreset(scopesPresets, { preset: trips?.scopes, hasAccessToPii })?.id;
  const userRole = userRoles(useUserPermissions());
  const tripAccessOptions = getWithPopupTripAccessOptions(getTripAccessOptionsRules(userRole));

  return (
    <TripAccessBaseContainer
      left={
        <div className="trip-access-container">
          <GeneralAccessInfo trips={trips} />
          <div>
            {breakpoints.sm && (
              <CanUser permission={Permission.ConsumersUpdate}>
                <Button primary onClick={onEdit}>
                  {i18n('ConsumerTripAccessInfo_EditButtonTitle')}
                </Button>
              </CanUser>
            )}
          </div>
        </div>
      }
      right={
        <>
          <div className="trip-access-container">
            <h4 className="trip-access-label">{i18n('ConsumerTripAccessInfo_TripsScopeLabel')}</h4>
            <div>
              {!breakpoints.sm && (
                <CanUser permission={Permission.ConsumersUpdate}>
                  <Button primary onClick={onEdit}>
                    {i18n('ConsumerTripAccessInfo_EditButtonTitle')}
                  </Button>
                </CanUser>
              )}
            </div>
          </div>
          <div className="trips-scope-radio-row">
            <RadioGroup
              padded="vertically"
              value={!selectedPresetId}
              groupName="trip-access-settings"
              disabled
              options={tripAccessOptions}
            />
          </div>
          {selectedPresetId && (
            <>
              <Dropdown
                className="trip-access-preset-dropdown"
                placeholder="-"
                value={selectedPresetId}
                disabled
                fluid
                selection
                options={getTripAccessPresetOption(scopesPresets)}
                data-testid="trip-access-preset-dropdown"
              />
              {trips?.scopes && (
                <TripAccessPresetVisualization
                  className="trip-access-preset-visualization"
                  model={cleanupTrueValues(trips?.scopes)}
                />
              )}
            </>
          )}
          {!selectedPresetId && (
            <TripAccessAdvancedSettings
              disabled
              settingsEntries={settingsEntries}
              setSettingsEntries={setSettingsEntries}
            />
          )}
        </>
      }
    />
  );
};

export default TripAccessInfo;
