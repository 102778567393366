import React, { useState } from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { ConsumerResponseModel } from '../../../models/Consumer/ConsumerResponseModel';
import { ScopesPresetModel } from '../../../models/Consumer/Scopes/ScopesPresetModel';
import { TripAccessFormViewModel } from '../../../models/Consumer/TripAccessFormViewModel';
import './styles.scss';
import TripAccessForm from './TripAccessForm/TripAccessForm';
import TripAccessInfo from './TripAccessInfo/TripAccessInfo';
import { useConsumerInfoNavigation } from '../../../hooks/Navigation/useConsumerInfoNavigation';
import { useTripAccessScopesPresets } from '../../../store/hooks/useTripAccessScopesPresets';
import { useTripAccessAdvancedSettings } from '../../../store/hooks/useTripAccessAdvancedSettings';

interface Props {
  consumer: ConsumerResponseModel;
  onConsumerTripAccessUpdated: (
    model: ConsumerResponseModel,
    tripAccess?: TripAccessFormViewModel,
    scopesPreset?: ScopesPresetModel[],
  ) => void;
}

const TripAccessTab: React.FC<Props> = ({ consumer, onConsumerTripAccessUpdated }) => {
  const [{ tripAccessScopesPresets, isLoading }] = useTripAccessScopesPresets();
  const [isEdit, setIsEdit] = useState(false);
  const [{ isLoading: isAdvancedSettingsLoading, getAdvancedSettingsEntries }] =
    useTripAccessAdvancedSettings();

  const [, { goToUrl }] = useConsumerInfoNavigation();

  const handleTripAccessSave = (model?: TripAccessFormViewModel) => {
    setIsEdit(false);
    onConsumerTripAccessUpdated(consumer, model, tripAccessScopesPresets);
  };

  return (
    <>
      {(isLoading || isAdvancedSettingsLoading) && (
        <Dimmer active inverted>
          <Loader size="massive" className="trip-access-loader" />
        </Dimmer>
      )}
      {consumer && (
        <Segment className="consumer-trip-access-info-container">
          {isEdit ? (
            <TripAccessForm
              model={consumer.trips}
              scopesPresets={tripAccessScopesPresets}
              getAdvancedSettingsEntries={getAdvancedSettingsEntries}
              onClose={(redirectUrl) => (redirectUrl ? goToUrl(redirectUrl) : setIsEdit(false))}
              onSave={(model) => handleTripAccessSave(model)}
            />
          ) : (
            <TripAccessInfo
              onEdit={() => setIsEdit(true)}
              getAdvancedSettingsEntries={getAdvancedSettingsEntries}
              trips={consumer.trips}
              scopesPresets={tripAccessScopesPresets}
            />
          )}
        </Segment>
      )}
    </>
  );
};

export default TripAccessTab;
