import { ApiAccess } from '../enums/ApiAccess';
import { formatUTCDateTime, formatUTCDateTimeWithUtcTextEnding } from '../helpers/TimeHelper';
import { AccountModel } from '../models/Consumer/Account/AccountModel';
import { AccountsModel } from '../models/Consumer/Account/AccountsModel';
import { ConsumerMetadataModel } from '../models/Consumer/ConsumerMetadataModel';
import { AccountExportModel } from '../models/Export/AccountExportModel';
import { ConsumerExportModel } from '../models/Export/ConsumerExportModel';
import { SmIdBaseExportModel } from '../models/Export/SmIdBaseExportModel';
import { i18n } from '../services/i18n';
import { ConsumerResponseModel } from './../models/Consumer/ConsumerResponseModel';
import { mapApiAccessList } from './ConsumerMapper';
import { isEmpty } from 'lodash';

const formatScvText = (text?: string) => (text ? `=""${text}""` : '');

const formatScvDate = (text?: string) => formatScvText(formatUTCDateTime(text));
const formatScvDateTimeUtc = (text?: string) => formatScvText(formatUTCDateTimeWithUtcTextEnding(text));

const getEnabledString = (enabled?: boolean) =>
  enabled ? i18n('EnabledStatusName') : i18n('DisabledStatusName');

const mapConsumerBaseToExportModel = (
  model: ConsumerMetadataModel | ConsumerResponseModel,
): Pick<ConsumerResponseModel, 'consumerKey' | 'consumerId' | 'oauthClientId'> => {
  return {
    consumerKey: formatScvText(model.consumerKey),
    consumerId: model.consumerId,
    oauthClientId: model.oauthClientId,
  };
};

export const mapToAccountExportModels = (
  consumer: ConsumerResponseModel,
  accountsData?: AccountsModel,
): AccountExportModel[] => {
  const getIsInclusiveString = (isInclusive?: boolean) =>
    isInclusive ? i18n('InclusiveLogicName') : i18n('ExclusiveLogicName');

  const mapConsumerToAccountExportModel = (
    model: ConsumerResponseModel,
    isInclusive: boolean,
  ): AccountExportModel => {
    return {
      consumer: {
        ...mapConsumerBaseToExportModel(model),
        isInclusiveAccountsLogic: getIsInclusiveString(isInclusive),
      },
    };
  };

  const mapToSmIdBaseExportModel = (model: AccountModel): SmIdBaseExportModel => {
    return {
      id: formatScvText(model.id),
      startDate: formatScvDate(model.startDate),
      endDate: formatScvDate(model.endDate),
      status: getEnabledString(model.enabled),
    };
  };

  const mapAccountToAccountExportModel = (model: AccountModel): AccountExportModel => {
    return {
      account: {
        ...mapToSmIdBaseExportModel(model),
        accountName: model.accountName,
        isInclusiveCustomersLogic: getIsInclusiveString(model.isInclusive),
      },
    };
  };

  const mapCustomerNumberToAccountExportModel = (model: AccountModel): AccountExportModel => {
    return { customerNumber: mapToSmIdBaseExportModel(model) };
  };

  const mappedConsumer = !isEmpty(consumer)
    ? mapConsumerToAccountExportModel(consumer, !!accountsData?.isInclusive)
    : {};
  const result: AccountExportModel[] = [mappedConsumer];

  accountsData?.ids?.forEach((a) => {
    const mappedAccount = mapAccountToAccountExportModel(a);
    result.push(mappedAccount);

    if (a.customerNumbers?.ids && a.customerNumbers.ids.length > 0) {
      const mappedCustomerNumbers = a.customerNumbers.ids.map(mapCustomerNumberToAccountExportModel);
      result.push(...mappedCustomerNumbers);
    }
  });
  return result;
};

export const mapToConsumerExportModels = (consumers?: ConsumerMetadataModel[]): ConsumerExportModel[] => {
  const mapConsumerToConsumerExportModel = (model: ConsumerMetadataModel): ConsumerExportModel => {
    return {
      ...mapConsumerBaseToExportModel(model),
      activeStartDate: formatScvDateTimeUtc(model.activeStartDate),
      activeEndDate: formatScvDateTimeUtc(model.activeEndDate),
      createdAt: formatScvDateTimeUtc(model.createdAt),
      createdBy: model.createdBy,
      lastUpdatedAt: formatScvDateTimeUtc(model.lastUpdatedAt),
      lastUpdatedBy: model.lastUpdatedBy,
      status: getEnabledString(model.enabled),
      apiAccess: mapApiAccessList(model)
        .map((access) => i18n(`ApiAccess_${ApiAccess[access]}`))
        .join(', '),
    };
  };

  return consumers && consumers.length > 0
    ? consumers.map((c) => mapConsumerToConsumerExportModel(c))
    : [{} as ConsumerExportModel];
};
