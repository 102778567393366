import { ApiAccess } from '../enums/ApiAccess';
import { cleanupTrueValues } from '../helpers/ObjectHelper';
import { getUTCDateByString } from '../helpers/TimeHelper';
import { TripsModel } from '../models/Consumer/ApiAccess/TripsModel';
import { ConsumerFormViewModel } from '../models/Consumer/ConsumerFormViewModel';
import { ContactModel } from '../models/Consumer/ContactModel';
import { ScopesPresetModel } from '../models/Consumer/Scopes/ScopesPresetModel';
import { ConsumerMetadataModel } from '../models/Consumer/ConsumerMetadataModel';
import { TripAccessFormViewModel } from '../models/Consumer/TripAccessFormViewModel';
import { ConsumerResponseModel } from './../models/Consumer/ConsumerResponseModel';
import { ConsumerRequestModel } from '../models/Consumer/ConsumerRequestModel';
import {
  getSelectedPreset,
  mapDataSourceFiltersModel,
  mapTripDataSourceFilterModel,
} from './DataSourceMapper';

const defaultContacts: { id: number; model: ContactModel }[] = [
  { id: 1, model: { email: '', isPrimary: false } },
];

export const mapApiAccessList = (model: ConsumerMetadataModel | ConsumerResponseModel): ApiAccess[] => {
  const access: ApiAccess[] = [];

  model.trips?.enabled && access.push(ApiAccess.Trips);
  model.expenses?.enabled && access.push(ApiAccess.Expenses);
  model.xx1?.enabled && access.push(ApiAccess.Xx1);
  model.waivers?.enabled && access.push(ApiAccess.WaiversStandAlone);
  model.waivers?.enabled && model.waivers?.match && access.push(ApiAccess.WaiversMatching);
  model.receipts?.enabled && access.push(ApiAccess.Receipts);
  model.tickets?.enabled && access.push(ApiAccess.Tickets);
  model.flightAlerts?.enabled && access.push(ApiAccess.FlightAlerts);
  model.enhApi?.enabled && access.push(ApiAccess.Enh);

  return access;
};

export const mapToConsumerFormViewModel = (source?: ConsumerResponseModel): ConsumerFormViewModel => {
  return source
    ? {
        consumerId: source.consumerId,
        consumerKey: source.consumerKey,
        enabled: source.enabled,
        activeStartDate: source.activeStartDate ? getUTCDateByString(source.activeStartDate) : undefined,
        activeEndDate: source.activeEndDate ? getUTCDateByString(source.activeEndDate) : undefined,
        contacts:
          source.contacts && source.contacts?.length > 0
            ? source.contacts?.map((contact, i) => ({ id: i, model: contact }))
            : defaultContacts,
        consumerTypes: source.consumerTypes,
        apiAccess: mapApiAccessList(source),
        hasOauthAccess: !!source.oauthClientId,
      }
    : {
        hasOauthAccess: false,
        enabled: false,
        contacts: defaultContacts,
        consumerTypes: [],
      };
};

export const mapToTripAccessModel = (
  model?: TripAccessFormViewModel,
  scopesPreset?: ScopesPresetModel[],
): TripsModel => {
  return {
    hasAccessToPii: model?.hasAccessToPii,
    hasAccessToRawData: model?.hasAccessToRawData,
    dataSourceFilters: mapTripDataSourceFilterModel(model?.dataSourceFilters ?? []),
    scopes: model?.isAdvancedSettings
      ? model.scopes
      : scopesPreset?.find((p) => p.id === model?.presetId)?.preset,
  };
};

export const updateConsumerModelTripAccess = (
  model: ConsumerResponseModel,
  tripAccess: TripsModel,
): ConsumerRequestModel => {
  return {
    ...model,
    trips: {
      ...model.trips,
      ...tripAccess,
      scopes: tripAccess.scopes,
    },
  };
};

export const mapToTripAccessFormViewModel = (
  source?: TripsModel,
  scopesPreset: ScopesPresetModel[] = [],
): TripAccessFormViewModel => {
  const hasAccessToPii = source?.hasAccessToPii ?? false;
  const selectedPreset = getSelectedPreset(scopesPreset, { preset: source?.scopes, hasAccessToPii });

  return {
    hasAccessToPii: hasAccessToPii,
    hasAccessToRawData: source?.hasAccessToRawData ?? false,
    dataSourceFilters: mapDataSourceFiltersModel(source?.dataSourceFilters ?? []),
    presetId: selectedPreset?.id,
    scopes: cleanupTrueValues(source?.scopes),
    isAdvancedSettings: !selectedPreset,
  };
};
