import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Permission } from '../../../enums/Permission';
import { formatUTCDateTimeWithUtcTextEnding } from '../../../helpers/TimeHelper';
import { i18n } from '../../../services/i18n';
import NamedLabel from '../../NamedLabel/NamedLabel';
import CanUser from '../../Permissions/CanUser';
import { ConsumerResponseModel } from './../../../models/Consumer/ConsumerResponseModel';
import './styles.scss';

interface Props {
  consumer: ConsumerResponseModel;
}

const ConsumerStaticInfo: React.FC<Props> = ({ consumer }) => {
  return (
    <div className="consumer-static-info-container">
      <NamedLabel title={i18n('ConsumerStaticInfo_ConsumerIdTitle')} value={consumer.consumerId} />
      <NamedLabel
        className="consumer-static-info-row"
        title={i18n('ConsumerStaticInfo_OauthIdTitle')}
        value={consumer.oauthClientId ?? i18n('OauthIdNotConfigured')}
      />
      <CanUser
        permission={Permission.ConsumersUpdate}
        negativeResponse={
          <NamedLabel
            className="consumer-static-info-row"
            title={i18n('ConsumerStaticInfo_OauthSecretTitle')}
            value={consumer.oauthClientSecret ? '•'.repeat(10) : i18n('OauthSecretNotConfigured')}
          />
        }
      >
        <NamedLabel
          hideContent={!!consumer.oauthClientSecret}
          className="consumer-static-info-row"
          title={i18n('ConsumerStaticInfo_OauthSecretTitle')}
          value={consumer.oauthClientSecret ?? i18n('OauthSecretNotConfigured')}
        />
      </CanUser>

      <Grid padded="vertically">
        <Grid.Row columns={2}>
          <Grid.Column>
            <NamedLabel
              className="consumer-static-info-grid-row"
              title={i18n('ConsumerStaticInfo_CreatedAtTitle')}
              value={formatUTCDateTimeWithUtcTextEnding(consumer.createdAt)}
            />
          </Grid.Column>
          <Grid.Column>
            <NamedLabel
              className="consumer-static-info-grid-row"
              title={i18n('ConsumerStaticInfo_CreatedByTitle')}
              value={consumer.createdBy}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched columns={2}>
          <Grid.Column stretched>
            <NamedLabel
              title={i18n('ConsumerStaticInfo_LastUpdatedAtTitle')}
              value={formatUTCDateTimeWithUtcTextEnding(consumer.lastUpdatedAt)}
            />
          </Grid.Column>
          <Grid.Column>
            <NamedLabel
              title={i18n('ConsumerStaticInfo_LastUpdatedByTitle')}
              value={consumer.lastUpdatedBy}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default ConsumerStaticInfo;
