import DropdownMenu from '../../../features/DropdownMenu/DropdownMenu';
import ExportButton from '../../../features/ExportButton/ExportButton';
import React, { memo, useRef, useState } from 'react';
import { AccountExportModel } from '../../../models/Export/AccountExportModel';
import { AccountsModel } from '../../../models/Consumer/Account/AccountsModel';
import { ConsumerResponseModel } from '../../../models/Consumer/ConsumerResponseModel';
import { ExportService } from '../../../services/ExportService';
import { i18n } from '../../../services/i18n';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Permission } from '../../../enums/Permission';
import { useUserPermissions } from '../../../hooks/Auth/useUserPermissions';
import './styles.scss';

interface Props {
  consumer: ConsumerResponseModel;
  accountsData: AccountsModel | undefined;
  onChangeInclusiveLogic: () => void;
}

const AccountsActionsMenu: React.FC<Props> = ({ consumer, accountsData, onChangeInclusiveLogic }) => {
  const menu = useRef<Menu>(null);
  const [exportedAccountsData, setExportedAccountsData] = useState<AccountExportModel[]>([]);
  const hasPermission = useUserPermissions();
  const exportService = ExportService;

  const handleAccountsExport = () => {
    const csvData = exportService.getExportedAccounts(consumer, accountsData);
    setExportedAccountsData(csvData);
  };

  const exportAccounsMenuItem: MenuItem = {
    template: (
      <ExportButton
        headers={exportService.getAccountsExportHeaders()}
        data={exportedAccountsData}
        filename={exportService.getAccountsFilename(consumer.consumerKey)}
        menu={menu}
        onExport={handleAccountsExport}
      />
    ),
  };

  const changeAccountsLogicItem: MenuItem = {
    label: i18n('ConsumerAccounts_ChangeAccountsLogicButton'),
    command: () => onChangeInclusiveLogic(),
  };

  const allMenuItems = hasPermission(Permission.ConsumersUpdate)
    ? [
        {
          items: [exportAccounsMenuItem, changeAccountsLogicItem],
        },
      ]
    : [{ items: [exportAccounsMenuItem] }];

  return <DropdownMenu menu={menu} menuItems={allMenuItems} />;
};

export default memo(AccountsActionsMenu);
