import React from 'react';
import { ApiAccess } from '../../enums/ApiAccess';
import { formatUTCDateTime } from '../../helpers/TimeHelper';
import { mapApiAccessList } from '../../mappers/ConsumerMapper';
import { i18n } from '../../services/i18n';
import '../Table/styles.scss';
import './ConsumerTable.scss';
import { ConsumerMetadataModel } from '../../models/Consumer/ConsumerMetadataModel';
import { DataTable, DataTableRowClickEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BooleanLabelPrime from '../BooleanLabel/BooleanLabelPrime';
import LabelGroupPrime from '../LabelGroup/LabelGroupPrime';
import NoDataRowPrime from '../Table/NoDataRow/NoDataRowPrime';

interface Props {
  items?: ConsumerMetadataModel[];
  noDataMessage: string;
  onConsumerOpen: (id: string) => void;
}

const ConsumerTable: React.FC<Props> = ({ items, noDataMessage, onConsumerOpen }) => {
  const consumersApiAccess: Record<string, ApiAccess[]> = React.useMemo(() => {
    const access: Record<string, ApiAccess[]> = {};
    items &&
      items.forEach((consumer) => {
        access[consumer.consumerId ?? ''] = mapApiAccessList(consumer);
      });
    return access;
  }, [items]);

  const renderActiveStartDate = (rowData: ConsumerMetadataModel) => {
    return formatUTCDateTime(rowData.activeStartDate);
  };

  const renderActiveEndDate = (rowData: ConsumerMetadataModel) => {
    return formatUTCDateTime(rowData.activeEndDate);
  };

  const renderOauthId = (rowData: ConsumerMetadataModel) => {
    return rowData.oauthClientId ?? i18n('OauthIdNotConfigured');
  };

  const renderStatus = (rowData: ConsumerMetadataModel) => {
    return (
      <BooleanLabelPrime
        value={rowData.enabled}
        textTrue={i18n('EnabledStatusName')}
        textFalse={i18n('DisabledStatusName')}
      ></BooleanLabelPrime>
    );
  };

  const renderApiAccess = (rowData: ConsumerMetadataModel) => {
    return (
      consumersApiAccess && (
        <LabelGroupPrime
          names={consumersApiAccess[rowData.consumerId ?? '']?.map((access) =>
            i18n(`ApiAccess_${ApiAccess[access]}`),
          )}
        ></LabelGroupPrime>
      )
    );
  };

  const handleRowClick = (event: DataTableRowClickEvent) => {
    const rowData = event.data as ConsumerMetadataModel;
    rowData.consumerId && onConsumerOpen(rowData.consumerId);
  };

  return (
    <DataTable
      value={items}
      tableClassName="data-table consumers-table"
      onRowClick={handleRowClick}
      scrollable
      virtualScrollerOptions={{ itemSize: 46, numToleratedItems: 6 }}
      scrollHeight="flex"
      emptyMessage={<NoDataRowPrime text={noDataMessage} />}
      data-testid="consumers-table"
    >
      <Column
        bodyClassName="consumer-key-column"
        field="consumerKey"
        header={i18n('ConsumerTable_Header_ConsumerName')}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-consumer-key' } }}
      ></Column>
      <Column
        bodyClassName={'consumer-id-column'}
        field="consumerId"
        header={i18n('ConsumerTable_Header_ConsumerId')}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-consumer-id' } }}
      ></Column>
      <Column
        bodyClassName={'oauth-id-column test-id-consumers-table-oauth-id'}
        field="oauthClientId"
        header={i18n('ConsumerTable_Header_ConsumerOAuthId')}
        body={renderOauthId}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-consumer-oauth-id' } }}
      ></Column>
      <Column
        bodyClassName={'date-column'}
        field="activeStartDate"
        header={i18n('Table_Header_StartDate')}
        headerClassName="column-header-tooltip"
        headerTooltip={i18n('Table_Header_DateTimeTooltip')}
        headerTooltipOptions={{ position: 'top' }}
        dataType="date"
        body={renderActiveStartDate}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-consumer-start-date' } }}
      ></Column>
      <Column
        bodyClassName={'date-column'}
        field="activeEndDate"
        header={i18n('Table_Header_EndDate')}
        headerClassName="column-header-tooltip"
        headerTooltip={i18n('Table_Header_DateTimeTooltip')}
        headerTooltipOptions={{ position: 'top' }}
        dataType="date"
        body={renderActiveEndDate}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-consumer-end-date' } }}
      ></Column>
      <Column
        bodyClassName={'enabled-column test-id-consumers-table-enabled'}
        field="enabled"
        header={i18n('ConsumerTable_Header_Status')}
        body={renderStatus}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-enabled' } }}
      ></Column>
      <Column
        bodyClassName={'api-access-column test-id-consumers-table-api-access'}
        field="consumerKey"
        header={i18n('ConsumerTable_Header_ApiAccess')}
        body={renderApiAccess}
        pt={{ bodyCell: { 'data-testid': 'consumers-table-api-access' } }}
      ></Column>
    </DataTable>
  );
};

export default ConsumerTable;
